.settings_content_row{
    background: #F0F0F3;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.break-time{
    font-size: 40px !important;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 95px;
    /* identical to box height */
  
    letter-spacing: 0.1em;
  
    color: #000000;
    padding: 0%;
    margin: auto;
  }

.break-time-input{
    font-size: 30px !important;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 95px;
    width: 100px;
    height: 50px;
  
    letter-spacing: 0.1em;
    color: #FB7575; 
    border: 0px none;
    background: #F0F0F3;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    text-align: center;
    margin: auto;
    outline: none;
}
.feedback-button{
    height: 50px;
    width: 250px;

    font-size: 30px !important;
    font-family: 'Rubik', sans-serif;
    font-style: normal;
    font-weight: 700;
    /* identical to box height */
  
    letter-spacing: 0.1em;
  
    color: #000000;

    border: 0px solid #000000;
    background: #F0F0F3;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25), -4px -4px 10px #FFFFFF;
    border-radius: 15px;
    text-align: center;
    padding: 0px;
    text-decoration: none;
 
}

.save-button{
    width: 100px;
    height: 50px;
    color: #000000;
    border: 0px none;
    background: #F0F0F3;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0px;
}

.save-button-img{
    width: 45px;
    height: 35px;
}