@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;700&display=swap');

.container{
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #F0F0F3;
  background: #F0F0F3;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: center;
}

.time{
  font-size: 40px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 95px;
  /* identical to box height */

  letter-spacing: 0.1em;

  color: #000000;
  margin-top: 0%;
  padding-top: 2%;
  background-color: #F0F0F3;

}



.title{
  font-size: 90px;
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 95px;
  /* identical to box height */

  letter-spacing: 0.1em;

  color: #000000;
  margin-top: 0%;
  padding-top: 2%;
  background-color: #F0F0F3;
}

.subtitle{

font-family: 'Rubik' , sans-serif;
font-style: normal;
font-weight: 300;
font-size: 30px;
line-height: 30px;
/* identical to box height */
align-items: center;
justify-content: center;
text-align: center;
letter-spacing: 0.1em;

color: #000000;
}


.cameraSwitch{
  align-items: center;
  text-align: center;
  justify-content: center;
}

.settings{
  padding-top: 1.5%;
  padding-right: 3%
}

.settings_menu{
  max-height: 26%;
  max-width: 33%;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  color: #000000;
  border: 0px none;
  background: #F0F0F3;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 50px;
}

.modal-body{
  background:  #F0F0F3 !important; 
}

/* this is to hide the seperators on modals */
.modal-header {
  border-bottom: 0 none !important;
}

.modal-content{
  border: 0 none
}

.modal-footer {
  border-top: 0 none !important;
}

.modal-backdrop
{
    opacity:0 !important;
}