.switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 34px;
    background: #EEEEEE;
    box-shadow: inset -1px -1px 1px rgba(255, 255, 255, 0.7), inset 1px 1px 2px rgba(174, 174, 192, 0.2);
    border-radius: 12px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    background: url("assets/shutter_icon.svg");
    background-size: 26px 26px;
  }
  
  input:checked + .slider {
    /*
    background: url("/public/camera_icon.png");
    background-size: 36px 32px;
    background-repeat: no-repeat;
    background-position: 4px , 4px;
    */
    background: linear-gradient(90deg, #FFB800 -52.16%, rgba(255, 255, 255, 0) 94.99%), #FB7575;
  
  }
  
  input:focus + .slider {
    /*
    box-shadow: 0 0 1px #2196F3;
    */
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(45px);
  }
  
  /* Rounded sliders */
  .slider.round {
    background: #F0F0F3;
    box-shadow: -1px -1px 10px rgba(174, 174, 192, 0.15), 3px 1px 4px rgba(174, 174, 192, 0.25);
    border-radius: 12px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }